ul {
  list-style: none;
  text-align: left;
}

ul li {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
}

.mobile-slide-body ul li {
  font-weight: 500;
}

.wrapper-slides {
  flex: 1.2;
  gap: 1rem;
  width: 70%;
  max-width: 1000px;
  /* padding-inline: 5rem; */
  display: flex;
}

.mobile-slider {
  display: flex;
  border-radius: 1.88rem;
  width: 100%;
  height: 100%;
}

.slide {
  display: flex;
  position: relative;
  background-color: rgb(83, 104, 104);
  width: 100%;
  flex: 1 0 auto;
  border-radius: 1.88rem;
  box-shadow: 2px 10px 10px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
}

.mobile-slide {
  display: flex;
  flex-direction: column;
  background-color: rgb(83, 104, 104);
  border-radius: 1.88rem;
  text-align: center;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  filter: drop-shadow(1px 6px 8px rgba(0, 0, 0, 0.25));
  height: 100%;
  width: 100%;
  flex: 1 0 auto;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.mobile-slide-body {
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  padding-block: 2rem;
  padding-inline: 2rem;
  color: var(--gray-800);
  background-color: var(--gray-100);
  flex: 1;
  transform: scaleY(1.08);
  box-shadow: 2px 10px 10px 0px rgba(0, 0, 0, 0.25);
}

.mobile-slide-image {
  overflow: hidden;
  flex: 1;
  border-radius: 1.88rem 1.88rem 0 0;
  background-position: center;
  background-size: cover;
}

.slide-image-left {
  overflow: hidden;
  flex: 1 1 auto;
  border-radius: 1.88rem 0 0 1.88rem;
  background-position: center;
  background-size: cover;
}

.slide-body {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: 100%;
  color: var(--gray-800);
  text-align: center;
  background-color: var(--gray-100);
  flex: 1.5 1 0px;
  transform: scaleY(1.08);
  box-shadow: 2px 10px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  padding-left: 2rem;
  padding-top: 3rem;
}

.slide-image-right {
  overflow: hidden;
  flex: 1 1 auto;
  border-radius: 0 1.88rem 1.88rem 0;
  background-position: center;
  background-size: cover;
}
