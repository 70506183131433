.list-element {
  display: flex;
  justify-content: start;
  gap: 0.5rem;
}

.list-bullet {
  flex: 0 0 auto;
  width: 1rem;
  aspect-ratio: 1/1;
  transform: translateY(-0.092rem);
  filter: invert(85%) sepia(0%) saturate(14%) hue-rotate(177deg) brightness(99%)
    contrast(96%);
}
