.motion-drawer-wrapper {
  position: fixed;
  z-index: 9999;
  right: 0;
  top: 0;
  height: 100%;
  width: 80%;
}

.nav-drawer {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: var(--primary-800);
  box-shadow: -4px 0px 8px 0px rgba(0, 0, 0, 0.25);
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  padding: 2rem;
}

.nav-drawer-close {
  align-self: flex-end;
  margin-bottom: 2rem;
}

.nav-drawer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 9;
  border: 1px solid hsla(54, 60%, 61%, 1);
  border-radius: 1.375rem;
  padding: 2rem;
}

.nav-drawer nav {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: var(--gray-100, #ebebeb);
  font-family: Istok Web;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1rem;
  text-align: center;
}

.nav-drawer nav a:hover {
  text-decoration: underline;
}
