.menu-bar {
  display: flex;

  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  margin-left: auto;
  border-radius: 0.5rem;
  width: 80%;
  max-width: 1300px;
  height: 4rem;
  justify-content: center;
  align-items: center;
  z-index: 9000;
}

.menu-bar-transparent {
  background-color: transparent;
  box-shadow: none;
  transition: 500ms ease-in;
}

.menu-bar-filled {
  background-color: var(--primary-800);
  box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.35);
  transition: 100ms ease-in;
}

.nav-menu-wrapper nav {
  display: flex;
  gap: 2rem;
  color: var(--gray-100, #ebebeb);
  font-family: Istok Web;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1rem;
}

.nav-menu-wrapper nav a {
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.nav-menu-wrapper nav a:hover {
  border-bottom: 2px solid var(--gray-100);
}

.nav-in-view {
  border-bottom: 2px solid var(--gray-100) !important;
}
