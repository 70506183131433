.page-ds {
  padding: 3rem;
  background-color: var(--gray-800);
  color: var(--gray-200);
}

.wrapper-ds {
  display: flex;
  margin-inline: auto;
  max-width: 1300px;
  flex-direction: column;
  gap: 1rem;
}

.ds-content {
  margin-bottom: 2rem;
}

.wrapper-ds ul {
  list-style: unset;
  font-weight: 400;
}

.wrapper-ds ul li {
  font-weight: unset;
}

.wrapper-ds a {
  text-decoration: underline;
}
