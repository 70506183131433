.dot {
  margin-top: 1rem;
  height: 0.6rem;
  border-radius: 1rem;
  aspect-ratio: 1/1;
  background-color: var(--gray-400);
}

.dot-selected {
  background-color: var(--gray-500);
}

.dot-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 2rem;
  /* margin-top: 0.5rem;
  margin-bottom: 3rem; */
}
