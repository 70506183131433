:root {
  /* Mode 1 */
  /* colors */
  --gray-100: rgb(235, 235, 235);
  --gray-200: rgb(208, 208, 208);
  --gray-300: rgb(182, 192, 189);
  --gray-400: rgb(143, 158, 154);
  --gray-500: rgb(115, 128, 124);
  --gray-600: rgb(70, 78, 76);
  --gray-700: rgb(34, 38, 37);
  --gray-800: rgb(29, 32, 32);
  --gray-900: rgb(26, 29, 28);
  --primary-100: rgb(214, 255, 245);
  --primary-200: rgb(189, 240, 227);
  --primary-300: rgb(176, 233, 219);
  --primary-400: rgb(150, 228, 209);
  --primary-500: rgb(128, 198, 180);
  --primary-600: rgb(100, 152, 139);
  --primary-700: rgb(72, 111, 101);
  --primary-800: rgb(30, 48, 44);
  --primary-900: rgb(22, 40, 36);
}

* {
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-display: swap;
  font-family: "Istok Web";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/istok-web-v24-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

body,
html,
#root {
  height: 100%;
  margin: 0;
  color: var(--gray-100);
  font-family: "Istok Web", sans-serif;
}

body,
html {
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
}

*::selection {
  background-color: var(--primary-700);
}

.flex-row {
  display: flex;
  gap: 1rem;
}

h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.title1 {
  font-weight: 700;
  font-size: 2.25rem;
  text-align: center;
}

.title2 {
  margin-top: 1rem;
  font-size: 2rem;
}

.burger-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background-color: var(--primary-800);
  padding: 0.8rem;
  border-radius: 0.6rem;
  z-index: 1000;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.burger {
  height: 1rem;
  width: 1rem;
}

.mobile-card-title1 {
  color: var(--gray-100, #ebebeb);
  text-align: center;
  font-family: Istok Web;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.1rem;
  margin-bottom: 2rem;
  z-index: 100;
}

.wrapper-content {
  flex-shrink: 1;
  transform: translateY(-1.2rem);
}

.page-hero {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  padding-inline: 1rem;
  text-align: center;
}

.page-welcome {
  display: flex;
  justify-content: center;
  color: black;
  text-align: start;
  padding-inline: 2rem;
  padding-top: 9rem;
  padding-bottom: 2rem;
}

.content-welcome {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
}

.logo {
  align-self: center;
  margin-top: 2rem;
  width: 90vw;
}

.page-introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-200);
  height: 300%;
  min-height: 40rem;
  width: 100%;
  padding-inline: 1rem;
  padding-block: 1rem;
  /* padding-bottom: 7rem; */
  overflow-x: hidden;
}

.wrapper-cards {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 1300px;
  gap: 3vw;
}

.mobile-card-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2rem;
  flex: 1 0 auto;
  width: 100%;
  height: 100%;
}

.mobile-card {
  position: relative;
  display: flex;
  flex: 1 1 0;
  width: 100%;
  height: 100%;
}

.card-container {
  display: flex;
  flex: 1 1 0;
}

.bg {
  background-image: url(../assets/background-uac-gradient.jpg);
  background-color: var(--gray-800);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.arrow-down {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  background-image: url(../assets/chevron-compact-down.svg);
  background-size: cover;
  filter: invert(85%) sepia(0%) saturate(14%) hue-rotate(177deg) brightness(99%)
    contrast(96%);
  height: 3rem;
  width: 3rem;
  animation: bounce 900ms alternate infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1rem);
  }
}

.row-3 {
  width: 100%;
  display: flex;
  justify-content: center;
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
}

.page-1 {
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
}

.column-impressum,
.column-contact {
  margin-inline: 2rem;
  margin-bottom: 2rem;
}

.page-contact {
  display: flex;
  background-color: var(--primary-800);
  padding-block: 3rem;
}

.section-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  margin-inline: auto;
  gap: 4rem;
}

.wrapper-contact-form {
  display: flex;
  flex: 4;
}

.headquarter {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-around;
  align-items: start;
  border-radius: 1.9rem;
  border: 2px solid hsla(54, 60%, 61%, 1);
  padding: 1rem;
  padding-block: 2rem;
  padding-left: 2rem;
  height: 60%;
}

.contact-wrapper {
  width: 100%;
}

/* .contact-form {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding-block: 8rem;
  padding-inline: 2rem;
  margin-inline: auto;
  margin-block: 2rem;
  width: 90%;
  border: 2px solid var(--gray-100);
  border-radius: 20px;
} */

.contact-form {
  display: grid;
  grid-template-columns: auto;
  column-gap: 1rem;
  row-gap: 1rem;
  position: relative;
  padding-block: 3rem;
  padding-inline: 1rem;
  width: 100%;
  border: 2px solid var(--gray-100);
  border-radius: 20px;
}

.contact-title {
  background-color: var(--primary-800);
  position: absolute;
  top: -2.3rem;
  left: 50%;
  transform: translate(-50%, 0);
  padding-inline: 0.5rem;
  text-wrap: nowrap;
}

@media only screen and (min-width: 768px) {
  .menu-bar {
    display: none;
  }

  .burger-button {
    display: none;
  }
  .page-1 {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  h1 {
    font-size: 3rem;
    font-weight: 400;
    margin-bottom: 1.3rem;
  }

  .wrapper-content {
    transform: translateY(0.45rem);
  }

  .title1 {
    font-weight: 700;
    font-size: 4rem;
    line-height: 130%;
  }
  .title2 {
    font-size: 3rem;
  }

  p {
    font-size: 1.25rem;
  }

  .arrow-down {
    height: 4rem;
    width: 4rem;
  }

  .page-welcome {
    padding-bottom: 4rem;
  }

  .page-introduction {
    height: 100%;
    padding-top: 7rem;
    /* padding-bottom: 3rem; */
  }

  .wrapper-cards {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    max-width: 1300px;
    gap: 3vw;
    margin-bottom: 4rem;
  }

  /* .contact-form {
    display: flex;
    gap: 1.5rem;
    padding-block: 8rem;
    margin-inline: auto;
    max-width: 1000px;
  }*/

  .section-contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: 1300px;
    padding: 2rem;
    margin-inline: auto;
    gap: 4rem;
  }

  .headquarter {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
    border-radius: 1.9rem;
    border: 2px solid hsla(54, 60%, 61%, 1);
    padding: 1rem;
    padding-left: 2rem;
    height: 60%;
  }

  .contact-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1.4fr 0.5fr auto 0.5fr;
    row-gap: 0.5rem;
    margin-inline: auto;
    padding-inline: 2rem;
    width: 100%;
    max-width: 1000px;
  }

  .textarea {
    grid-column: span 2;
  }

  .button-submit {
    grid-column: span 2;
    margin-inline: auto;
    margin-top: 1rem;
  }

  .contact-title {
    top: -2.3rem;
    left: 1.8rem;
    right: unset;
    transform: unset;
    padding-inline: 1rem;
  }
}
