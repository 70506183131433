.card-body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: rgb(66, 83, 70);
  flex: 1 1 0;
  border-radius: 30px;
  padding: 0.5rem;
  padding-bottom: 2rem;
  text-align: center;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  filter: drop-shadow(1px 6px 8px rgba(0, 0, 0, 0.25));
  z-index: 20;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.gradient {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0.7) 100%
  );
  z-index: 0;
}

.card-title {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.6rem;
  z-index: 10;
  margin-bottom: 2rem;
  text-align: center;
  margin-inline: auto;
}

.card-arrow {
  height: 3rem;
  width: 3rem;
  margin: 0;
  filter: invert(1%) sepia(1%) saturate(191%) hue-rotate(66deg) brightness(116%)
    contrast(84%);
  z-index: 1000;
}

@media only screen and (min-width: 768px) {
  .card-title {
    font-size: 2.5rem;
    font-weight: 700;
  }
}
