.footer {
  background-color: var(--gray-800);
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-family: Istok Web;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-wrapper {
  display: flex;
  gap: 0.5rem;
}

.footer-decoration {
  position: absolute;
  transform: translateY(-3rem);
  background-color: hsla(54, 60%, 61%, 1);
  height: 2px;
  width: 100%;
}

.mb-logo {
  position: absolute;
  transform: translateY(-3.25rem);
  z-index: 9000;
}
